
import { isMobile, isTablet } from '~/helpers/mobile/DeviceType'
import { smoothScrollToTargetId } from '~/helpers/util'
import baseConstants from '~/store/base/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import modalConstants from "~/store/modal/-constants";

const NOT_IN_VIEW = 'is-not-intersecting'

export default {
  name: 'MoleculeSidebarResult',
  props: {
    building: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    floor: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      isSpaceListExpanded: false,
      listData: [],
      filters: {},
      clickedHighlight: '',
      observer: null
    }
  },
  computed: {
    isFullyLeasedEnabled() {
      return this.floor.isFullyLeased && (!this.visibleLeasedSpaces || !this.includeLeasedSpacesInResultsList);
    },
    isVisibleFullyLeasedEnabled() {
      return this.floor.isFullyLeased && this.visibleLeasedSpaces;
    },
    buildingStore() {
      return this.$store.state.building
    },
    floorSpaces() {
      // TODO: [Check what is showRentedSpaces]
      return this.showRentedSpaces
        ? this.floor.spaces
        : this.floor?.spaces?.filter(
            (s) =>
              (s.available && s.visibility_status !== 'hidden') ||
              s.visibility_status === 'available'
          )
    },
    filteredSpaces() {
      if (this.visibleLeasedSpaces) {
        return (
          this.floor?.spaces?.filter(
            (s) =>
              (s.visibility_status !== 'hidden') || s.visibility_status === 'available'
          ).filter((s) => this.includeLeasedSpacesInResultsList ? true : s.available) || []
        )
      }
      return (
        this.floor?.spaces?.filter(
          (s) =>
            (s.available && s.visibility_status !== 'hidden') || s.visibility_status === 'available'
        ) || []
      )
    },
    availableSpacesCount() {
      return this.filteredSpaces.filter((s) => s.available).length;
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    includeLeasedSpacesInResultsList() {
      return this.$store.state.base.includeLeasedSpacesInResultsList
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    showRentedSpaces() {
      return this.$store.state.base.meta.generalConfig.showRentedSpaces ?? true
    },
    spaces() {
      return this.$store.state.project.project.spaces
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    buildings() {
      return this.$store.state?.project?.project?.buildings || []
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject);
    },
    disableList() {
      return this.projectFeatures?.disableMyList;
    },
    visibleLeasedSpaces() {
      return this.projectFeatures?.visibleLeasedSpaces;
    },
    listSpaceDetails() {
      return this.projectFeatures?.listSpaceDetails || ['sqm', 'people'];
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    isEmbedPath() {
      return this.$route.path.includes('/embed')
    },
    isEmbedV2Path() {
      return this.$route.path.includes('/embed/v2');
    },
  },
  mounted() {
    this.initObserver()
    this.observeElements()
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    spaceAvailability(available, visibility) {
      switch (visibility) {
        case 'available':
          return true
        case 'default':
          return available
        case 'hidden':
          return false
        default:
          return available
      }
    },
    viewSpace(space, floor, buildingCode) {
      const floorCode = floor.code
      const floorTypeCode = floor.floor_type.model
      const spaceCode = space.space_code
      this.$store.dispatch('building/viewSpace', {
        space: spaceCode,
        building: buildingCode,
        floor: floorCode,
        floorType: floorTypeCode,
        spaceData: space
      })

      const manager = this.engine3d.getClientManager()
      if (manager) {
        manager.showPinsByCategory('none');
        manager.hideHighlight(false);
        const spacesCode = spaceCode.split(";");
        manager.highlightBuildingSpace(buildingCode.toLowerCase(), floorCode, spacesCode, space.available)
      }

      if (isMobile() || isTablet()) {
        this.$store.dispatch(
          baseConstants.withNamespace(baseConstants.action.CHANGE_MOBILE_AVAILABILITY_MENU),
          false
        )
      }
    },
    exploreSpace(spaceCode, buildingCode, floorCode, space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'explore_space_trigger',
          payload: {
            zone: 'building',
            spaceCode,
            buildingCode,
            floorCode
          }
        })
      }
      if (!this.isEmbedPath) {
        this.$router.push({
          path: `/project/${this.activeProject}/space/${space.id}`
        })
      } else {
        this.$router.push({
          path: `/embed/${(this.isEmbedV2Path ? 'v2/' : '')}${this.activeProject}/space/${space.id}`
        })
      }
    },
    resetView() {
      const manager = this.engine3d.getClientManager()
      if (manager) {
        manager.hideHighlight(true);
        if (isMobile() || isTablet()) {
          manager.showPinsByCategory('none');
          this.$store.dispatch('building/setDefaultFilterPinsMode', 'none')
        } else {
          manager.showPinsByCategory('');
          this.$store.dispatch('building/setDefaultFilterPinsMode', '')
        }
      }
      this.$store.dispatch('building/clearSpaceData')
    },
    viewOrHideSpace(space, floor, buildingCode) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'toggle_space_on_building',
          payload: {}
        })
      }
      if (isMobile() || isTablet()) {
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_MOBILE_AVAILABILITY_MENU))
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE), false)
      }
      const getSiblings = function (elem) {
        const descendants = elem.parentNode.children
        return Array.prototype.filter.call(descendants, function (sibling) {
          return sibling !== elem
        })
      }
      const target = event.target
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove('activated')
      })
      target.classList.toggle('activated')
      const closestFloorRow = event.target.closest('.row-floor')
      getSiblings(closestFloorRow).forEach((sibling) => {
        sibling.classList.remove('activation')
      })
      closestFloorRow.classList.toggle('activation')

      if (closestFloorRow.classList.contains('activation')) {
        this.viewSpace(space, floor, buildingCode)
      } else {
        this.resetView()
      }

      const buildingsList = document?.getElementsByClassName('building-list') || []
      if (buildingsList && buildingsList.length > 0) {
        for (const bl of buildingsList) {
          const rows = bl?.querySelectorAll('.row-floor') || []
          if (rows && rows.length > 0) {
            for (const childRow of rows) {
              if (childRow !== closestFloorRow) {
                childRow.classList.remove('activation')
              }
            }
          }
        }
      }
    },
    addSpaceToCartAndOpenRequestOffer(space, building, floor) {
      const spaceData = {
        ...space,
        building,
        floor
      }
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'building'
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        spaceData
      )
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    },
    addSpaceToCart(space, building, floor) {
      const spaceData = {
        ...space,
        building,
        floor
      }
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'add_space_to_list',
          payload: {
            zone: 'building',
            space
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        spaceData
      )
    },
    removeSpaceFromCart(space, building, floor) {
      const spaceData = {
        ...space,
        building,
        floor
      }
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'remove_space_from_list',
          payload: {
            zone: 'building',
            space
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        spaceData
      )
    },
    addBothSpacesToCart(space, combinedSpace, building, floor, combinedFloor) {
      const spaceData = {
        ...space,
        building,
        floor
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        spaceData
      )
      const combinedSpaceData = {
        ...combinedSpace,
        building,
        floor: combinedFloor
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        combinedSpaceData
      )
    },
    removeBothSpacesFromCart(space, combinedSpace, building, floor, combinedFloor) {
      const spaceData = {
        ...space,
        building,
        floor
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        spaceData
      )
      const combinedSpaceData = {
        ...combinedSpace,
        building,
        floor: combinedFloor
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        combinedSpaceData
      )
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId)
    },
    getCombinedSpace(id) {
      if (!this.spaces) {
        return {
          floor: {}
        }
      }
      const foundSpace = this.spaces.find((s) => s.id === id)
      if (!foundSpace) {
        return null
      }
      foundSpace.floor = this.floors.find((f) => f.id === foundSpace.floor_id)
      return foundSpace
    },
    initObserver() {
      const spaceResultScrollParent = this.$parent?.$parent?.$refs?.spaceResultsScroll || null
      if (spaceResultScrollParent) {
        this.observer = new IntersectionObserver(this.onElementObserved, {
          root: spaceResultScrollParent,
          rootMargin: '0px 0px -10% 0px',
          threshold: 1.0
        })
      }
    },
    onElementObserved(entries) {
      if (!entries || entries.length === 0) return
      entries
        .filter((entry) => !entry.target.classList.contains('action-disabled'))
        ?.forEach(({ target, isIntersecting }) => {
          target.classList.remove('is-not-intersecting')
          if (!isIntersecting) {
            target.classList.add('is-not-intersecting')
          }
          // this.observer.unobserve(target); // we want to observe the elements everytime
        })
    },
    observeElements() {
      if (this.observer && this.$refs.parentFloorRef) {
        this.observer.observe(this.$refs.parentFloorRef)
      }
    },
    handleFloorClick(buildingCode, floorCode) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'toggle_floor_space_list',
          payload: {
            opened: !this.isSpaceListExpanded,
            buildingCode,
            floorCode
          }
        })
      }
      if (!this.isSpaceListExpanded) {
        if (this.$refs.parentFloorRef.classList.contains(NOT_IN_VIEW)) {
          const id = `#${buildingCode}_${floorCode}`
          setTimeout(() => {
            smoothScrollToTargetId(id, 'smooth', 'center', 'nearest')
          }, 300)
        }
      }
      if (this.filteredSpaces && this.filteredSpaces.length === 1 && !this.isSpaceListExpanded) {
        const space = this.filteredSpaces[0];
        const targetElement = document.querySelector(`div[data-building="${buildingCode}"][data-floor="${this.floor.code}"][data-space="${space.space_code}"]`);
        targetElement.classList.add('activation');
        setTimeout(() => {
          this.viewSpace(space, this.floor, buildingCode);
        }, 100)
      }
      else if (this.filteredSpaces && this.filteredSpaces.length === 1 && this.isSpaceListExpanded) {
        const space = this.filteredSpaces[0];
        const targetElement = document.querySelector(`div[data-building="${buildingCode}"][data-floor="${this.floor.code}"][data-space="${space.space_code}"]`);
        targetElement.classList.remove('activation');
        this.resetView();
      }
      this.isSpaceListExpanded = !this.isSpaceListExpanded
    }
  }
}
